<template>
  <div class="articleRviewed-container">
    <div class="header">
      <van-nav-bar
        title="管理文章"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
      </van-nav-bar>
    </div>

    <div class="set-status" v-if="typeLoading">
      <div class="Is-comto-material">
        <p class="comto-material">是否推入素材库</p>
        <div class="select-comin">
          <div
            :class="['selectBox', boxId === it.id ? 'bgColor' : '']"
            v-for="it in isMaterial"
            :key="it.id"
            @click="handleComtomaterial(it.id)"
          >
            {{ it.title }}
          </div>
        </div>
      </div>
      <!-- 多选素材 -->
      <div class="more-select-material" v-if="choiceMaterialShow">
        <p class="comto-material">选择素材库</p>
        <div class="grid-material">
          <div
            :class="[
              'grid-material-item',
              merbox.includes(it.materialId) ? 'bgColor' : '',
            ]"
            v-for="it in materialList"
            :key="it.materialId"
            @click="handleChoiceMaterial(it.materialId)"
          >
            {{ it.materialName }}
          </div>
        </div>
      </div>

      <div class="article-type" v-if="choiceMaterialShow">
        <p>分类</p>
<!--        <div class="select-like-type">-->
<!--          <div class="grid-type">-->
<!--            <div-->
<!--              :class="['grid-type-item', box === item.typeId ? 'bgColor' : '']"-->
<!--              v-for="item in moreTypeList"-->
<!--              :key="item.typeId"-->
<!--              @click="handleChoice(item.typeId)"-->
<!--            >-->
<!--              {{ item.typeName }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="select-like-type" v-for="(it) in choiceMaterialType" :key="it.materialId">
          <div style="text-align: left;margin-bottom: 5px">{{it.materialName}}</div>
          <div class="grid-type">
            <div
                v-for="item in it.materialCategoryList"
                :key="item.categoryPrimaryId"
                @click="handleChoice(item.categoryPrimaryId)"
                :class="['grid-type-item', materialType.includes(item.categoryPrimaryId) ? 'bgColor' : '']"
            >
              {{ item.materialCategoryName }}
            </div>
          </div>
        </div>
      </div>

      <div class="is_top">
        <div class="Is-comto-material">
          <p class="comto-material">是否置顶</p>
          <div class="select-comin">
            <div
              :class="['selectBox', boxId2 === item.id ? 'bgColor' : '']"
              v-for="item in isTop"
              :key="item.id"
              @click="handleComtoTop(item.id)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <van-grid clickable :column-num="2" safe-area-inset-bottom>
        <van-grid-item
          ><van-button
            round
            type="primary"
            block
            color="#BFBFBF"
            @click="handleBlack"
            >返回</van-button
          ></van-grid-item
        >
        <van-grid-item
          ><van-button
            round
            type="primary"
            block
            color="#FF6E01"
            @click="handleSaveUpdata"
            >保存</van-button
          ></van-grid-item
        >
      </van-grid>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  updateArticleMana,
  findArticleType,
  getArticleById,
  findMaterial,
  getMaterialAndCategory,
} from "../../api/articleManagement";
import { Toast } from "vant";

export default {
  setup() {
    // const checked = ref('')
    // const checked2 = ref('')
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      // articleId:route.query.id,
      saveMaterialType:[],
      materialType:[],
      materialArr:[],
      choiceMaterialType:[],
      choiceMaterialShow: false,
      boxId: "",
      boxId2: "",
      box: "",
      merbox: [],
      materialStr: "",
      typeLoading: false,
      articleId: route.query.id,
      articleReviewed: {
        articleId: route.query.id,
        isMaterial: null,
        isTop: null,
        type: null,
        belongMaterial: "",
      },
      moreTypeList: [
        // {id:1,title:'装修利器'},
        // {id:2,title:'装修利器'},
        // {id:3,title:'装修利器'},
        // {id:4,title:'装修利器'},
      ],
      materialList: [],
      isMaterial: [
        { id: 1, title: "是" },
        { id: 0, title: "否" },
      ],
      isTop: [
        { id: 1, title: "是" },
        { id: 0, title: "否" },
      ],
    });
    //推送素材库
    const handleComtomaterial = (id) => {
      state.boxId = id;
      state.articleReviewed.isMaterial = id;
      if (id === 1) {
        state.choiceMaterialShow = true;
        if (state.articleReviewed.belongMaterial === "0") {
          state.articleReviewed.belongMaterial = state.materialStr;
        }
      } else {
        state.choiceMaterialShow = false;
        state.merbox = [];
        state.articleReviewed.belongMaterial = "0";
        state.materialStr = "";
        state.materialType = []
        state.saveMaterialType = []
        state.materialArr = []
      }
    };
    //置顶
    const handleComtoTop = (id) => {
      state.boxId2 = id;
      state.articleReviewed.isTop = id;
    };

    //获取文章信息
    const loadArticle = async () => {
      let result = await getArticleById(state.articleId);
      state.articleReviewed.isMaterial = result.isMaterial;
      state.articleReviewed.type = result.type;
      state.articleReviewed.isTop = result.isTop;
      state.articleReviewed.belongMaterial = result.belongMaterial;
      state.boxId = result.isMaterial;
      state.boxId2 = result.isTop;
      state.box = result.type;
      if(result.type){
        state.materialType = result.type.split(",").map(Number);
        console.log("文章详情的分类",state.materialType)
      }
      if (result.isMaterial === 1) {
        state.materialStr = result.belongMaterial +",";
        state.merbox = result.belongMaterial.split(",");
        state.merbox = state.merbox.map(Number);
      } else {
        state.articleReviewed.belongMaterial = result.belongMaterial;
      }
      if (result.isMaterial === 1) {
        state.choiceMaterialShow = true;
        let param = {
          modeId:1,//模块ID 1-文章 2-案例 3-视频 4-海报
          materialId:result.belongMaterial
        }
        getMaterialAndCategory(param).then((res)=>{
          state.choiceMaterialType = res
        })
      } else {
        state.choiceMaterialShow = false;
      }

    };
    loadArticle();

    //保存
    const handleSaveUpdata = async () => {
      // let save = false
      if (state.articleReviewed.isMaterial === 1 && state.merbox.length === 0) {
        Toast.fail("请选择推送的素材库");
      } else if (state.merbox.length>state.saveMaterialType.length&&state.articleReviewed.isMaterial === 1){
        Toast.fail("请选择素材库相应的分类");
        console.log("state.merbox=====>",state.merbox)
        console.log("state.saveMaterialType====",state.saveMaterialType)
        console.log("state.materialArr====",state.materialArr)
      }else {
        let params={
              articleId: route.query.id,
              isMaterial: state.articleReviewed.isMaterial,
              isTop: state.articleReviewed.isTop,
              type: state.articleReviewed.isMaterial === 1?state.saveMaterialType.toString():'',
              belongMaterial: state.articleReviewed.isMaterial === 1?state.merbox.toString():0,
        }

        let result = await updateArticleMana(params);
        if (result) {
          router.back();
        }
      }
    };
    const handleBlack = () => {
      router.back();
    };
    //获取文章分类
    const loadArticleMoreType = async () => {
      let result = await findArticleType();
      state.moreTypeList = result;
      state.typeLoading = true;
    };
    loadArticleMoreType();

    //获取素材库
    const loadMaterialList = async () => {
      let result = await findMaterial();
      state.materialList = result;
    };
    loadMaterialList();

    //选择素材库

    const handleChoiceMaterial = (it) => {
      state.saveMaterialType = []
      if (state.merbox.includes(it)) {
        state.merbox.splice(state.merbox.indexOf(it), 1);
      } else {
        if (state.materialStr === "") {
          state.merbox.push(it);
        } else {
          state.merbox.push(it);
        }
      }
      state.materialArr = []
      state.merbox.map((it,index)=>{
        let p = {
          id:it,
          materialCategoryList:[]
        }
        state.materialArr[index] = p
      })
      // console.log('选择的素材库',state.materialArr)
      state.materialStr = state.merbox.toString()
      state.articleReviewed.belongMaterial = state.materialStr;
      let param = {
        modeId:1,//模块ID 1-文章 2-案例 3-视频 4-海报
        materialId:state.materialStr
      }
      getMaterialAndCategory(param).then((res)=>{
        console.log("获取所有素材库分类=================",res)
        state.choiceMaterialType = res
        res.map(it=>{
          state.materialArr.map((item,index)=>{
            if(item.id === it.materialId){
              state.materialArr[index].materialCategoryList = it.materialCategoryList
            }
          })
        })

        state.materialArr.map((it)=>{
          it.materialCategoryList.map(j=>{
            state.materialType.map(item=>{
              if (j.categoryPrimaryId===item){
                state.saveMaterialType.push(item)
              }
            })
          })

        })
        console.log("可以保存的分类",state.saveMaterialType)
      })
    };
    //选择分类
    const handleChoice = (id) => {

      state.box = id;
      state.articleReviewed.type = id;
      if (state.materialType.indexOf(id)===-1){
        state.materialType.push(id)
      }else {
        let len = state.materialType.indexOf(id)
        state.materialType.splice(len,1)
      }
      state.saveMaterialType = []
      state.saveMaterialType = state.materialType
    };

    return {
      ...toRefs(state),
      handleSaveUpdata,
      handleChoiceMaterial,
      handleChoice,
      handleComtoTop,
      handleComtomaterial,
      handleBlack,
    };
  },
};
</script>

<style lang="scss" >
.articleRviewed-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  //    text-align: left;
  font-size: 14px;
  // width: 100%;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }

  .set-status {
    flex: 1;
    overflow-x: hidden;
    // height: 641px;
    padding: 0 18px;
    .Is-comto-material {
      margin-bottom: 50px;
      font-size: 14px;
      // text-align: center;
      .comto-material {
        font-weight: 600;
        // padding: 18px;
        text-align: left;
      }
      .select-comin {
        .selectBox {
          float: left;
          color: #999999;
          width: 52px;
          height: 28px;
          border: 1px solid #999999;
          line-height: 28px;
          margin-right: 18px;

          border-radius: 4px;
          box-sizing: border-box;
        }
        .bgColor {
          color: #fff;
          background-color: #289fff;
          border: none;
        }
      }
    }
    .more-select-material {
      .comto-material {
        font-weight: 600;
        // padding: 18px;
        text-align: left;
      }
      .grid-material {
        // padding-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        // border-radius: 10px;
        // justify-content: space-between;
        justify-content: flex-start;
        .grid-material-item {
          //   color: #999999;
          //    width: 72px;
          height: 28px;
          padding: 0 20px;
          margin-right: 6px;
          margin-bottom: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 28px;
          //   border: 1px solid #999999;
          background-color: #f1f3f6;
        }
        .bgColor {
          //   height: 30px;
          //   padding: 0 21px;
          color: #ffffff;
          background-color: #ffcf23;
          border: none;
        }
      }
    }

    .article-type {
      > p {
        font-weight: 600;
        text-align: left;
      }
      .grid-type {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .grid-type-item {
          height: 28px;
          //   width: 72px;
          padding: 0 20px;
          margin-bottom: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 28px;
          background-color: #f1f3f6;
        }
        .bgColor {
          background-color: #ffcf23;
          color: #fff;
        }
      }
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    // left: 50%;
    // transform: translateX(-50%);
    .van-button--round {
      border-radius: 8px;
      height: 44px;
      width: 160px;
    }
    [class*="van-hairline"]::after {
      border: none;
    }
  }
}
</style>